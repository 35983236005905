import {
  accessoryPrice,
  getFormattedPrice,
} from "@components/WalletForm/models/products";
import s from "./CardholderPrice.module.scss";
import { useComputed } from "@preact/signals";

const DEFAULT_CARDHOLDER_PRICE = 29.9;

export const CardholderPrice = () => {
  const price = useComputed(() =>
    getFormattedPrice(accessoryPrice || DEFAULT_CARDHOLDER_PRICE),
  );
  return <span className={s.price}>{price}</span>;
};
